<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="auto"> </v-col>
      </v-row>
      <v-row align="start">
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              <div class="flex-grow-1">Floor Plan Image</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.planDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Image</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="property.floor_plan_document">
              <v-img
                :src="property.asset_urls.floor_plan_document"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
                contain
              >
              </v-img>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <room-dimentions-dialog
            ref="roomDimentionsDialog"
            :property="property"
          />
        </v-col>
      </v-row>
    </v-container>
    <plan-dialog ref="planDialog" />
    <delete-image-dialog ref="deleteImageDialog" />
  </div>
</template>

<script>
import PlanDialog from "./components/PlanDialog.vue";
import DeleteImageDialog from "./images/DeleteMediaDialog.vue";
import roomDimentionsDialog from "./components/RoomDimentionsDialog.vue";

export default {
  components: {
    PlanDialog,
    DeleteImageDialog,
    roomDimentionsDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
      dialog: false,
      loading: false,
      isEditing: false,
      images: {},
      fields: {
        name: null,
        url: null,
        type: null,
        propery_id: null,
      },
      errors: {},
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  methods: {
    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveImages", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
